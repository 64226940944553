<template>
  <div>
    <div class="Search_main">
      <img
        class="Search_main_img"
        src="@/assets/imagel/selfsearch.png"
        alt=""
      />
    </div>
    <div class="hospotal_name">
      <van-cell title="福 贡 县 人 民 医 院">
        <template slot="label">
          <span style="color:white;">THE PEOPLE'S HOSPITAL OF FUGONG</span>
        </template>
      </van-cell>
      <!-- <van-cell title="江 城 县 人 民 医 院">
                 <template slot="label">
                   <span style="color:white;">THE PEOPLE'S HOSPITAL OF JIANGCHENG</span>
                 </template>
               </van-cell> -->
      <!-- <van-cell title="镇 沅 县 人 民 医 院">
                 <template slot="label">
                   <span style="color:white;">THE PEOPLE'S HOSPITAL OF ZHENYUAN</span>
                 </template>
               </van-cell> -->
      <!-- <van-cell title="澜 沧 县 第 一 人 民 医 院">
                 <template slot="label">
                   <span style="color:white;">THE PEOPLE'S HOSPITAL OF LANCANG</span>
                 </template>
               </van-cell> -->
    </div>

    <van-grid :column-num="4">
      <van-grid-item>
        <van-icon
          slot="icon"
          class="iconfont"
          class-prefix="icon"
          name="guahao"
          color="#57c4b7"
          size="48"
          @click="onclickReg"
        />
        <span slot="text" style="color:#57c4b7; font-size:14px;">挂号记录</span>
      </van-grid-item>
      <van-grid-item>
        <van-icon
          slot="icon"
          class="iconfont"
          class-prefix="icon"
          name="jiaofei"
          color="#57c4b7"
          size="48"
          @click="onclickFee"
        />
        <span slot="text" style="color:#57c4b7; font-size:14px;">缴费记录</span>
      </van-grid-item>
      <van-grid-item>
        <van-icon
          slot="icon"
          class="iconfont"
          class-prefix="icon"
          :name="ybicon"
          color="#57c4b7"
          size="48"
          @click="onclickmpay"
        />
        <span slot="text" style="color:#57c4b7; font-size:14px;"
          >医保缴费查询</span
        >
      </van-grid-item>
      <van-grid-item>
        <van-icon
          slot="icon"
          class="iconfont"
          class-prefix="icon"
          name="xiangmuchaxun"
          color="#57c4b7"
          size="48"
          @click="onclickNoDrugs"
        />
        <span slot="text" style="color:#57c4b7; font-size:14px;">项目查询</span>
      </van-grid-item>
      <van-grid-item>
        <van-icon
          slot="icon"
          class="iconfont"
          class-prefix="icon"
          name="h__icon"
          color="#57c4b7"
          size="48"
          @click="onclickDrugs"
        />
        <span slot="text" style="color:#57c4b7; font-size:14px;">药品查询</span>
      </van-grid-item>
      <van-grid-item>
        <van-icon
          slot="icon"
          class="iconfont"
          class-prefix="icon"
          :name="zhuyaunIcon"
          color="#57c4b7"
          size="48"
          @click="onclickHosPInfo"
        />
        <span slot="text" style="color:#57c4b7; font-size:14px;">住院查询</span>
      </van-grid-item>
      <van-grid-item>
        <van-icon
          slot="icon"
          class="iconfont"
          class-prefix="icon"
          :name="hesuanIcon"
          color="#57c4b7"
          size="48"
          @click="onclickQCcardlist"
        />
        <span slot="text" style="color:#57c4b7; font-size:14px;">核酸记录</span>
      </van-grid-item>
      <!-- <van-grid-item >
          <img src="@/assets/imagel/Registration.png" @click="onclickReg" />
          </van-grid-item>
          <van-grid-item>
            <img src="@/assets/imagel/Payment.png" @click="onclickFee"/>
          </van-grid-item>
          <van-grid-item>
            <img src="@/assets/imagel/Projects.png" @click="onclickNoDrugs"/>
          </van-grid-item>
          <van-grid-item>
            <img src="@/assets/imagel/Drug.png" @click="onclickDrugs"/>
          </van-grid-item> -->
    </van-grid>
    <div
      style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>
<script>
import "@/assets/iconfont/search/iconfont.css";

export default {
  data() {
    return {
      zhuyaunIcon: require("../../assets/zhuyuan.png"),
        hesuanIcon:require("../../assets/hesuan.png"),
        ybicon:require('../../assets/medical-logo.png')
    };
  },

  created() {},
  methods: {
    onclickReg() {
      this.$router
        .replace({
          //挂号记录
          path: "/QRCardL",
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onclickmpay() {
          this.$router.replace({
            path: '/mpaycardl'   //医保缴费记录
          }).catch(err => {
                console.log(err);
            });
      },
    onclickFee() {
      this.$router
        .replace({
          path: "/QFCardL", //缴费记录
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onclickNoDrugs() {
      //项目查询
      this.$router
        .replace({
          path: "/NoDrugs",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onclickDrugs() {
      //药品查询
      this.$router
        .replace({
          path: "/Drugs",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onclickHosPInfo() {
      this.$router
        .replace({
          path: "/QHcardL",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onclickQCcardlist(){
      // 核酸查询
      this.$router
        .replace({
          path: "/qcCardL",
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>

<style lang="less" scoped>
// .van-grid{
//   padding: 2%;
// }

.Search_main_img {
  width: 100%;
}
.hospotal_name {
  position: absolute;
  top: 0;
  // margin: 10px;
  .van-cell {
    margin: 0;
    padding: 5px;
    color: white;
    background: rgba(0, 0, 0, 0);
  }
}
</style>
